/* General Section Styling */
.why-choose-us {
    padding: 2rem;
    background-color: #ffffff; /* Light background */
}

.why-choose-us__title {
    font-size: 2.3rem;
    font-weight: bold;
    text-align: left;
    color: #03045e; /* Deep navy blue */
    margin-left: 130px;
    position: relative;
}

.why-choose-us__title::after {
    content: "";
    display: block;
    width: 60px;
    height: 3px;
    background: linear-gradient(90deg, #ab9ef5, #7c62df);
    margin-left: 25px;
}

/* Features Styling */
.why-choose-us__features {
    display: flex;
    margin-top: 60px;
    justify-content: center;
    gap: 6rem;
    flex-wrap: wrap;
}

.why-choose-us__feature {
    display: flex; /* Flexbox for horizontal alignment of icon and text */
    align-items: flex-start;
    gap: 1.2rem; /* Spacing between icon and text */
    max-width: 280px;
    text-align: left;
}

/* Icon Styling with Gradient */
.why-choose-us__icon {
    width: 20px; /* Narrow rectangle width */
    height: 60px; /* Tall rectangle height */
    background: linear-gradient(135deg, #a1a0c3, #7f709d); /* Gradient background */
    border-radius: 5px; /* Slightly rounded corners */
    flex-shrink: 0; /* Prevent the icon from shrinking */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add slight shadow for depth */
}

/* Title Styling with Gradient */
.why-choose-us__feature-title {
    font-family: "Poppins", sans-serif; /* Poppins font */
    font-size: 1.6rem;
    font-weight: 600;
    background: linear-gradient(90deg, #1e1984, #6651bb);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; /* Gradient text effect */
    margin-bottom: 0.8rem;
    transition: transform 0.2s ease-in-out; /* Add smooth hover effect */
}

.why-choose-us__feature-title:hover {
    transform: scale(1.03); /* Slightly enlarge on hover */
}

/* Description Styling */
.why-choose-us__feature-description {
    font-family: "Inter", sans-serif; /* Inter font */
    margin-left: 40px;
    font-size: 1.3rem;
    color: #606060; /* Neutral gray */
    line-height: 1.5;
}

@media (max-width: 768px) {
    .why-choose-us {
        padding: 1.5rem; /* Adjust padding for smaller screens */
    }

    .why-choose-us__title {
        font-size: 1.8rem; /* Reduce font size for better readability */
        text-align: center; /* Center-align title for smaller screens */
        margin-left: 0; /* Remove left margin */
    }

    .why-choose-us__title::after {
        text-align: center;
        margin-left: 80px; /* Center underline */
    }

    .why-choose-us__features {
        flex-direction: column; /* Stack features vertically */
        align-items: center;
        gap: 2rem; /* Reduce gap for better spacing */
    }

    .why-choose-us__feature {
        max-width: 90%; /* Allow features to fit within the viewport */
        text-align: center; /* Center-align text and icon */
        gap: 1rem; /* Adjust spacing between icon and text */
    }

    .why-choose-us__icon {
        width: 15px; /* Reduce icon size */
        height: 50px; /* Adjust icon height */
        margin: 0 auto; /* Center icon */
    }

    .why-choose-us__feature-title {
        font-size: 1.4rem; /* Adjust title size */
        margin-bottom: 0.6rem;
    }

    .why-choose-us__feature-description {
        font-size: 1.1rem; /* Reduce description font size */
        margin-left: 0; /* Remove left margin */
    }
}
