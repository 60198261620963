/* General Section Styling */
.key-features {
  position: relative;
  padding: 4rem 2rem;
  overflow: hidden;
  clip-path: none; /* No diagonal edge */
  background-color: #ffffff; /* Subtle base color */
}

.key-features::before {
  content: "";
  position: absolute;
  top: -150px;
  left: -100px;
  width: 300px;
  height: 300px;
  background: radial-gradient(circle, rgba(141, 130, 199, 0.2), transparent);
  border-radius: 50%;
  z-index: 0;
}

.key-features::after {
  content: "";
  position: absolute;
  bottom: -150px;
  right: -100px;
  width: 300px;
  height: 300px;
  background: radial-gradient(circle, rgba(141, 130, 199, 0.2), transparent);
  border-radius: 50%;
  z-index: 0;
}

/* Decorative Line Element */
.key-features__line {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, rgba(171, 158, 245, 0.3), rgba(124, 98, 223, 0.3));
  z-index: 0;
  transform: translateY(-50%);
  pointer-events: none; /* Ensures no interaction */
}

.key-features__title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #03045e;
  margin-bottom: 3rem;
  font-family: "Poppins", sans-serif;
  text-align: center;
  position: relative;
  z-index: 1; /* Keeps title above background */
}

.key-features__title::after {
  content: "";
  display: block;
  width: 100px;
  height: 4px;
  background: linear-gradient(90deg, #ab9ef5, #7c62df);
  margin: 0.5rem auto 0;
  border-radius: 2px;
}

/* Features List Styling */
.key-features__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 7.5rem;
  z-index: 1; /* Keeps items above background */
}

.key-features__item {
  flex: 1 1 calc(33.33% - 2rem); /* Flexible 3-column grid */
  max-width: 300px;
  position: relative;
  text-align: left;
  border-left: 5px solid #ab9ef5; /* Vertical highlight */
  padding: 1.5rem 1rem;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  z-index: 1; /* Keeps items above background */
}

.key-features__item:hover {
  background: linear-gradient(145deg, #ffffff, #f4efff); /* Gentle gradient */
  border-left: 5px solid #19027869; /* Highlighted left border */
  box-shadow: 0 10px 25px rgba(113, 169, 195, 0.277); /* Enhanced shadow */
  cursor: pointer;
}

.key-features__item-title {
  font-size: 1.5rem;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #03045e;
  margin-bottom: 1rem;
}

.key-features__item-description {
  font-size: 1.2rem;
  color: #606060;
  line-height: 1.6;
  font-family: "Inter", sans-serif;
}
/* Responsive Styling for Key Features Section */
@media (max-width: 1024px) {
  .key-features {
    padding: 3rem 1.5rem; /* Adjusted padding for smaller screens */
  }

  .key-features__title {
    font-size: 2rem; /* Slightly smaller title font size */
  }

  .key-features__title::after {
    width: 80px; /* Adjusted width for the underline */
  }

  .key-features__list {
    gap: 4rem; /* Reduced gap between items */
  }

  .key-features__item {
    flex: 1 1 calc(50% - 1rem); /* 2-column layout */
    max-width: 45%; /* Adjust width to fit within columns */
    padding: 1.2rem; /* Reduced padding */
  }

  .key-features__item-title {
    font-size: 1.4rem; /* Slightly smaller title font */
  }

  .key-features__item-description {
    font-size: 1rem; /* Adjusted description font size */
  }
}

@media (max-width: 768px) {
  .key-features {
    padding: 2.5rem 1rem; /* Further reduced padding */
  }

  .key-features__title {
    font-size: 1.8rem; /* Even smaller title font */
    margin-bottom: 2rem;
  }

  .key-features__title::after {
    width: 60px; /* Smaller underline width */
  }

  .key-features__list {
    flex-direction: column; /* Stack items vertically */
    gap: 2.5rem; /* Reduced gap for vertical layout */
  }

  .key-features__item {
    flex: 1 1 100%; /* Full-width for each item */
    max-width: 90%; /* Limit item width */
    padding: 1rem; /* Compact padding */
  }

  .key-features__item-title {
    font-size: 1.3rem; /* Smaller title font */
  }

  .key-features__item-description {
    font-size: 0.95rem; /* Adjusted description font size */
  }
}

@media (max-width: 480px) {
  .key-features {
    padding: 2rem 1rem; /* Compact padding for extra small screens */
  }

  .key-features__title {
    font-size: 1.5rem; /* Reduced title font size */
    margin-bottom: 1.5rem;
  }

  .key-features__list {
    gap: 1.5rem; /* Minimal gap between items */
  }

  .key-features__item {
    padding: 0.8rem; /* Compact padding */
    border-left: 4px solid #ab9ef5; /* Slightly thinner border */
  }

  .key-features__item-title {
    font-size: 1.2rem; /* Smaller title font size */
  }

  .key-features__item-description {
    font-size: 0.9rem; /* Compact description font */
    line-height: 1.4; /* Tighter line height */
  }
}
