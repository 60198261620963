/* General Styles for the Additional CTA */
.additional-cta {
  text-align: center; /* Center the text and button */
  margin: 40px 0px 0px 0px; /* Add spacing around the CTA */
}

/* Styling for the Sentence */
.cta-text {
  padding: 10px;
  text-align: center;
  justify-content: center;
  font-size: 36px; /* Font size for the sentence */
  line-height: 40px; /* Line height for better readability */
  color: #03055eaa; /* Dark blue color for the sentence */
  font-family: "Poppins", sans-serif; /* Poppins font */
  font-weight: 600; /* Semi-bold weight */
  margin-bottom: 30px; /* Space below the sentence */
}

.addcta-button {
  margin-top: 20px;
  display: inline-block;
  padding: 15px 30px;
  font-size: 13px;
  font-weight: 600;
  color: #fff; /* White text for contrast */
  background-color: #1e188ed8; /* Slightly darker indigo background */
  border-radius: 30px; /* Fully rounded edges for a modern look */
  box-shadow: 0px 4px 10px rgba(63, 58, 169, 0.3); /* Subtle shadow matching the darker color */
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative; /* Needed for pseudo-elements */
  overflow: hidden; /* Hide overflow for hover effects */
}

.addcta-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%; /* Start from outside the button */
  width: 200%; /* Wide enough to cover the button */
  height: 100%;
  background: linear-gradient(120deg, rgba(255, 255, 255, 0.3), transparent);
  transition: all 0.4s ease;
}

.addcta-button:hover::before {
  left: 100%; /* Animate across the button */
}

.addcta-button:hover {
  background-color: #1b1477; /* Darker indigo on hover for contrast */
  box-shadow: 0px 6px 15px rgba(63, 58, 169, 0.5); /* Stronger shadow for hover state */
  transform: translateY(-3px); /* Lift effect */
}

.addcta-button:active {
  transform: translateY(0); /* Neutral position when clicked */
  box-shadow: 0px 4px 10px rgba(63, 58, 169, 0.3); /* Original shadow */
}

@media (max-width: 768px) {
  .cta-text {
    padding: 10px;
    text-align: center;
    justify-content: center;
    font-size: 26px; /* Font size for the sentence */
    line-height: 40px; /* Line height for better readability */
    color: #03055eaa; /* Dark blue color for the sentence */
    font-family: "Poppins", sans-serif; /* Poppins font */
    font-weight: 600; /* Semi-bold weight */
    margin-bottom: 10px; /* Space below the sentence */
  }
}
