.scroll-progress-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0);
  }
  
  .scroll-progress-bar {
    height: 100%;
    background: linear-gradient(90deg, #9087c7, #03055ec1);
    transition: width 0.2s ease-out;
  }
  