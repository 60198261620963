.blog-content h1 {
  @apply text-4xl font-bold;
}

.blog-content h2 {
  @apply text-3xl font-semibold;
}

.blog-content h3 {
  @apply text-2xl font-semibold;
}

.blog-content p {
  @apply text-base;
}
