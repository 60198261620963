/* Hero Section */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.hero {
  background-color: #f5f7fe; /* Soft lavender blue for the hero section background */
  padding: 50px 2%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; /* Wrap for smaller screens */
}

.hero-content {
  margin-left: 100px;
  max-width: 50%;
  text-align: center; /* Align text to the left */
}

.hero-heading {
  font-size: 3rem; /* Adjusted font size */
  font-weight:600; /* Bold and confident heading */
  color: #03045E; /* Darker navy blue for the heading */
  line-height: 1.3;
  font-family: 'Montserrat', sans-serif; /* Use Montserrat for the heading */
  margin-bottom: 40px;
}

.hero-subheading {
  font-size: 1.5rem;
  font-weight: 400; /* Normal weight */
  color: #03045E; /* Subtle lavender for subheading text */
  margin-bottom: 40px;
  line-height: 1.5;
}
/* CTA Button Container */
.cta-button-container {
  width: 100%; /* Take full width */
  text-align: center; /* Center the button */
  margin-left: 30px;
  margin-top: -35px; /* Move button slightly upwards */
}

.cta-button {
  display: inline-block;
  padding: 15px 30px;
  font-size: 13px;
  font-weight: 600;
  color: #fff; /* White text for contrast */
  background-color: #1e188ed8; /* Slightly darker indigo background */
  border-radius: 30px; /* Fully rounded edges for a modern look */
  box-shadow: 0px 4px 10px rgba(63, 58, 169, 0.3); /* Subtle shadow matching the darker color */
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative; /* Needed for pseudo-elements */
  overflow: hidden; /* Hide overflow for hover effects */
}

.cta-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%; /* Start from outside the button */
  width: 200%; /* Wide enough to cover the button */
  height: 100%;
  background: linear-gradient(120deg, rgba(255, 255, 255, 0.3), transparent);
  transition: all 0.4s ease;
}

.cta-button:hover::before {
  left: 100%; /* Animate across the button */
}

.cta-button:hover {
  background-color: #1b1477; /* Darker indigo on hover for contrast */
  box-shadow: 0px 6px 15px rgba(63, 58, 169, 0.5); /* Stronger shadow for hover state */
  transform: translateY(-3px); /* Lift effect */
}

.cta-button:active {
  transform: translateY(0); /* Neutral position when clicked */
  box-shadow: 0px 4px 10px rgba(63, 58, 169, 0.3); /* Original shadow */
}

/* Hero Image Section */
.hero-image-container {
  max-width: 50%;
  margin-right: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Background for Hero Image */
.hero-image-background {
  position: absolute;
  margin-bottom: 180px;
  z-index: 1; /* Keep it behind the main image */
}

.hero-image-background img {
  width: 100%; /* Ensure the background covers the entire section */
  height: auto;
  object-fit: cover; /* Ensure it scales proportionally */
}

/* Main Hero Image */
.hero-image {
  margin-bottom: 210px;
  margin-left: 50px;
  position: relative; /* Positioned on top of the background */
  z-index: 2;
}

.hero-image img {
  margin-left: 15px;
  margin-bottom: 40px;
  width: 80%; /* Scale the main image */
  max-width: 400px; /* Limit max size */
  border-radius: 15px; /* Optional styling */
}

@media (max-width: 1024px) {
  .hero {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px 5%;
  }

  .hero-content {
    max-width: 90%;
    margin: 0 auto 20px auto;
  }

  .hero-heading {
    font-size: 2rem;
    line-height: 1.3;
    margin-bottom: 15px;
  }

  .hero-subheading {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }

  .cta-button-container {
    margin: -180px auto 20px auto;
    text-align: center;
  }

  .hero-image-container {
    max-width: 90%;
    margin: 20px auto 0 auto;
  }

  .hero-image img {
    width: 100%; /* Adjust image to fit smaller screens */
    max-width: 300px;
    margin: 0 auto;
    border-radius: 10px; /* Keep styling intact */
  }

  .hero-image-background img {
    display: none; /* Hide background for clarity on smaller screens */
  }
}
