/* General Settings */
body {
  font-family: "Poppins", sans-serif;
  background-color: #ffffff;
}

/* Footer Section */
.footer {
  background-image: url("/public/Footer bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: #6f6f71;
  padding: 80px 10px 0px 10px;
}

.footer-section {
  flex: 1;
  margin: 10px;
}
.footer-section h2 {
  font-size: 1.8rem;
  font-weight: 600;
  color: #03045e;
  text-align: center;
  text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.3); /* Drop shadow */
}
.footer-section h3 {
  font-size: 1.7rem;
  color: #03045e;
  margin-bottom: 15px;
  text-align: center;
  text-shadow: 1px 4px 5px rgba(0, 0, 0, 0.3); /* Drop shadow */
}

.footer-section ul {
  list-style: none;
  line-height: 2rem;
  padding: 0;
  margin: 0;
  text-align: center;
}

.footer-section ul li {
  margin: 5px 0;
}

.footer-section ul li a {
  text-decoration: none;
  font-size: 1rem;
  color: #03045e; /* Dark blue for text */
  transition: color 0.3s ease;
  font-weight: 500; /* Medium font */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3); /* Drop shadow */
}

.footer-section ul li a:hover {
  color: #4f9da6;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 25px; /* Spacing between icons */
  margin-top: 50px;
}

.social-icons a {
  font-size: 1.5rem; /* Icon size */
  color: rgba(2, 13, 32, 0.67); /* Icon color with 67% opacity */
  background-color: #ffffff; /* White circular background */
  border-radius: 50%; /* Make the background circular */
  width: 50px; /* Fixed width for the icon container */
  height: 50px; /* Fixed height for the icon container */
  display: flex;
  align-items: center; /* Center the icon vertically */
  justify-content: center; /* Center the icon horizontally */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add subtle shadow around the icon */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add hover animations */
}

.social-icons a:hover {
  transform: scale(1.1); /* Slight zoom effect on hover */
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15); /* Stronger shadow on hover */
}

.footer-bottom {
  width: 100%;
  text-align: center;
  margin-top: 10px;
  display: flex;
  border-top: 1px solid #dcdcdc;
  padding-top: 10px;
  justify-content: space-between; /* Push the elements to the left and right */
}

.footer-bottom p {
  justify-content: flex-start;
  font-size: 0.9rem;
  margin: 5px 5px;
  color: #2f2f34;
}

.footer-bottom a {
  color: #03045e;
  justify-content: flex-end;
  text-decoration: none;
  margin: 0 15px;
}

.footer-bottom a:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    text-align: center;
    margin: 20px 0;
  }

  .social-icons {
    justify-content: center;
  }
}
