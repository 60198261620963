/* Base header styling */

.header {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: #ffffff; /* Default background color */
  padding: 20px 50px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #f6f6f2;
  height: 80px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

/* Header when scrolled */
.header.scrolled {
  background-color: #ffffff; /* Background color after scrolling */
  color: #03045e; /* Text color after scrolling */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  padding: 15px 50px; /* Adjust padding for compact look */
  transition: all 0.3s ease;
}

/* Logo styling */
.logo {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: -50px;
  font-family: Merriweather, serif; /* Added fallback font */
  color: #03045e; /* Blue text for logo */
  text-decoration: none;
  gap: 15px; /* Space between logo icon and text */
}

/* Shrink logo on scroll */
.header.scrolled .logo {
  transform: scale(0.9); /* Slightly shrink logo on scroll */
}

/* Base styles for Sign-In button */
.Sign-In {
  font-size: 0.9rem;
  color: #ffffff; /* White text */
  background: linear-gradient(
    90deg,
    #03045e,
    #033d88
  ); /* Blue gradient background */
  border: none;
  border-radius: 30px;
  padding: 10px 30px;
  cursor: pointer;
  position: relative;
  overflow: hidden; /* To hide the hover effects going beyond the button */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  font-family: lato;
  /* New shadow effect */
  box-shadow: 0 5px 8px rgba(3, 4, 94, 0.44); /* Y-offset: 5px, Blur: 8px, Color: #03045E with 44% opacity */
}

/* Adding hover animation */
.Sign-In:hover {
  transform: scale(1.05); /* Slightly enlarges the button */
  box-shadow: 0 8px 16px rgba(3, 4, 94, 0.44); /* Enhances shadow on hover for a lifted effect */
  background: linear-gradient(90deg, #023e8a, #03045e); /* Reverse gradient */
}

/* Add gradient shimmer animation */
.Sign-In::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.2)
  );
  transform: skewX(-20deg);
  transition: all 0.3s ease;
  z-index: 0; /* Keep behind the button content */
}

/* Shimmer effect on hover */
.Sign-In:hover::before {
  left: 100%; /* Moves the gradient shimmer across the button */
}

/* Ensuring button text stays above the hover effects */
.Sign-In p {
  font-size: smaller;
  position: relative;
  z-index: 1; /* Keeps the text visible above the hover effects */
  margin: 0;
}

/* Menu icon styling */
.menu-toggle {
  display: none; /* Hidden by default */
  cursor: pointer;
  font-size: 2rem;
  color: #f6f6f2;
  transition: color 0.3s ease;
}
/* Navigation links */

.nav a {
  margin: 0 15px;
  color: #03045e;
  text-decoration: none;
  font: weight 9px;
  position: relative;
  padding: 5px 0;
  margin-right: 45px;
  transition: color 0.5s ease, font-weight 0.5s ease;
}

/* Enhanced hover effect */
.nav a:hover {
  color: #03045e; /* Light blue on hover */
  font-weight: bold; /* Bold text */
}

/* Add sliding underline effect */
.nav a::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: linear-gradient(90deg, #a1b2c8, #03045e); /* Gradient underline */
  transition: width 0.3s ease;
}

.nav a:hover::before {
  width: 100%; /* Slide underline across the link */
}

.nav a.active-link {
  font-weight: bold;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .header {
    width: 100%;
    max-width: 100%;
  }

  .nav {
    display: none; /* Hide navigation on mobile by default */
  }
  .logo {
    margin-right: 50px;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    text-wrap: nowrap;
    font-weight: bold;
    font-family: Merriweather, serif; /* Added fallback font */
    color: #03045e; /* Blue text for logo */
    text-decoration: none;
    gap: 1px; /* Space between logo icon and text */
  }
  .logo img {
    margin-left: -50px;
    width: 4rem;
  }
  .menu-toggle {
    display: block; /* Show menu toggle on mobile */
  }

  .header {
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: #ffffff; /* Ensure header background */
  }

  .Sign-In {
    font-size: 0.7rem;
    text-wrap: nowrap;
    color: #ffffff; /* White text */
    background: linear-gradient(
      90deg,
      #03045e,
      #033d88
    ); /* Blue gradient background */
    border: none;
    border-radius: 30px;
    text-align: center;

    cursor: pointer;
    position: relative;
    overflow: hidden; /* To hide the hover effects going beyond the button */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    font-family: lato;
    /* New shadow effect */
    box-shadow: 0 5px 8px rgba(3, 4, 94, 0.44); /* Y-offset: 5px, Blur: 8px, Color: #03045E with 44% opacity */
  }

  /* Menu toggle button for mobile */
  .menu-toggle {
    display: block;
    cursor: pointer;
    width: 30px;
    height: 24px; /* Total height to fit three lines */
    position: relative;
    z-index: 1200;
    transition: transform 0.3s ease;
  }

  /* Single line styling */
  .menu-toggle span {
    display: block;
    width: 100%;
    height: 3px; /* Thickness of each line */
    background-color: #03045e;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    transition: all 0.3s ease;
    margin-left: 30px;
  }

  /* Top line */
  .menu-toggle span::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #03045e;
    top: -8px; /* Spacing above middle line */
    left: 0;
    transition: all 0.3s ease;
  }

  /* Bottom line */
  .menu-toggle span::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #03045e;
    top: 8px; /* Spacing below middle line */
    left: 0;
    transition: all 0.3s ease;
  }

  /* Show nav when menu is active */
  .nav.active {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 80px; /* Adjust to match header height */
    left: 0;
    width: 100%;
    background-color: #6061b1;
    z-index: 10;
    padding: 20px 0;
    border-top: 1px solid #ddd;
  }

  /* Default nav link styling */
  .nav a {
    padding: 10px;
    font-size: 1.2rem;
    color: #f6f6f2;
  }

  /* Active link underline effect */
  .nav a.active-link::after,
  .nav a:hover::after {
    width: 15%; /* Adjust percentage for underline */
    background-color: #f6f6f2; /* Underline color */
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    height: 2px;
    transform: translateX(-50%);
    transition: width 0.3s ease;
  }

  /* Ensure underline stays visible when scrolled */
  .header.scrolled .nav a.active-link::after {
    width: 15%; /* Maintain underline for active link after scrolling */
    background-color: #ffffff; /* Set underline color to match link */
  }

  /* Active link color when scrolled */
  .header.scrolled .nav a.active-link {
    color: #ffffff; /* Active link stays visible even after scrolling */
  }

  /* Hover effect for nav links */
  .nav a:hover {
    color: #ffffff; /* Ensure color change on hover */
  }

  /* Adjust hover underline when scrolled */
  .header.scrolled .nav a:hover::after {
    background-color: #ffffff; /* Hover underline color after scrolling */
  }
}
