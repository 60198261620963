/* Importing Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&family=Inter:wght@400;500&display=swap");

/* General Section Styling */
.how-it-works {
  padding: 2rem 2rem;
  font-family: "Poppins", sans-serif;
  background-color: #f8fafc;
  position: relative;
}

/* Section Title */
.how-it-works__title {
  text-align: left;
  font-size: 2.5rem;
  font-weight: 600;
  margin-left: 140px;
  margin-bottom: 4rem;
  color: #00296b;
}
.how-it-works__title::after {
  content: "";
  display: block;
  width: 60px;
  height: 3px;
  background: linear-gradient(90deg, #ab9ef5, #7c62df);
  margin-left: 25px;
}
/* Timeline Container */
.how-it-works__timeline {
  position: relative;
  margin: 0 auto;
  max-width: 900px;
  padding-left: 20px;
}

/* Vertical Line */
.how-it-works__line {
  position: absolute;
  top: 0;
  left: 50%;
  width: 4px;
  height: 100%;
  border-radius: 5px;
  background: linear-gradient(180deg, #aa9fd4, #2f28bca1);
  transform: translateX(-50%);
  z-index: 1;
}

/* Individual Step */
.how-it-works__step {
  position: relative;
  gap: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
}

.how-it-works__step:nth-child(odd) {
  flex-direction: row;
  justify-content: flex-end;
  text-align: left;
}

.how-it-works__step:nth-child(even) {
  flex-direction: row-reverse;
  justify-content: flex-start;
  text-align: left;
}

.how-it-works__step::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 20px;
  background: #fff;
  border: 4px solid #1a65a8;
  border-radius: 50%;
  z-index: 2;
}

/* Image Container */
.how-it-works__image-container {
  flex: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.how-it-works__image {
  width: 180px; /* Static size for images */
  height: 180px; /* Static size for images */
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0 4px 12px rgba(144, 160, 205, 0.289);
}

/* Text Content */
.how-it-works__content {
  flex: 0.9;
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  z-index: 2;
  position: relative;
  margin-top: -2rem; /* Aligns closer to the timeline */
}

.how-it-works__step-title {
  font-size: 1.6rem;
  font-weight: 600;
  color: #03045e;
  margin-bottom: 0.5rem;
}

.how-it-works__description {
  font-size: 1.2rem;
  color: #606060;
  line-height: 1.6;
}
@media (max-width: 768px) {
  .how-it-works {
    padding: 1.5rem 1rem; /* Reduce padding for smaller screens */
  }

  .how-it-works__title {
    font-size: 2rem; /* Reduce font size */
    text-align: center; /* Center-align title */
    margin-left: 0; /* Remove left margin */
    margin-bottom: 2rem; /* Adjust bottom margin */
  }

  .how-it-works__title::after {
    margin: 0 auto; /* Center underline */
  }

  .how-it-works__timeline {
    padding-left: 0; /* Remove padding */
    max-width: 100%; /* Utilize full width */
  }

  .how-it-works__line {
    left: 50%; /* Center the timeline line */
    width: 3px; /* Adjust thickness */
  }

  .how-it-works__step {
    display: block;
    align-items: center; /* Center-align content */
    margin-bottom: 0rem; /* Adjust spacing between steps */
    text-align: center; /* Center-align text */
  }

  .how-it-works__step:nth-child(odd),
  .how-it-works__step:nth-child(even) {
    justify-content: center; /* Remove alignment differences */
  }

  .how-it-works__step::before {
    top: -20px; /* Adjust positioning for smaller screens */
    left: 50%; /* Center align the dot */
    transform: translateX(-50%);
  }

  .how-it-works__image-container {
    padding: 0.5rem; /* Reduce padding */
    margin-bottom: 1rem; /* Add spacing between image and content */
    visibility: hidden;
  }

  .how-it-works__image {
    width: 0px; /* Scale image size */
    height: 0px;
  }

  .how-it-works__content {
    width: 20rem;
    margin: 0 auto;
    padding: 1.5rem; /* Reduce padding */
    text-align: center; /* Center-align content */
  }

  .how-it-works__step-title {
    font-size: 1.4rem; /* Reduce font size */
    margin-bottom: 0.5rem;
  }

  .how-it-works__description {
    font-size: 1rem; /* Reduce font size */
    line-height: 1.4; /* Adjust line height */
  }
}
