/* General layout */
.about-us-container {
  padding: 3rem; /* Increased padding for more space */
  font-family: 'Arial', sans-serif;
  color: #374957;
  max-width: 1200px;
  margin: 0 auto;
}

.about-us-section {
  text-align: center;
  margin-bottom: 4rem; /* Added spacing below section */
}

.about-us-section h1 {
  font-size: 2.5rem; /* Adjusted to match Figma */
  color: #388087;
  margin-bottom: 1rem;
}

.about-description {
  font-size: 1.4rem; /* Adjusted to match Figma */
  color: #374957;
  line-height: 1.8; /* Increased line height for better readability */
  margin-bottom: 2.5rem; /* Added more spacing between text and button */
  max-width: 700px;
  margin: 0 auto;
}

.view-more-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}

.view-more-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #51b0b5; /* Button background color */
  color: #000000;
  border: none;
  padding: 0.9rem 1.5rem; /* Reduced padding */
  font-size: 1rem;
  position: relative;
  cursor: pointer;
  box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.2); /* Inner shadow */
  transition: background-color 0.3s ease;
}

.view-more-btn:hover {
  background-color: #388087; /* Hover effect */
}

.view-more-btn .circle {
  background-color: #ffffff;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  position: absolute; /* Position the circle relative to the button */
  bottom: -10px; /* Slightly lower but not fully at the bottom */
  right: 4px; /* Move it slightly to the left, not exactly on the edge */
  border-radius: 50%; /* Circular shape */
  box-shadow: inset 0px 4px 8px rgba(65, 64, 64, 0.2);
}

.view-more-btn .circle i {
  font-size: 1rem; /* Icon size */
  position: relative;
  z-index: 2;
}

.view-more-btn:hover .circle i {
  transform: translateX(2px);
  transition: transform 0.3s ease;
}

/* Divider */
.divider {
  border: none;
  height: 2px;
  background-color: #c8c7c7;
  margin: 3rem 0;
}

/* Interactive Models Section */
.interactive-models-section {
  text-align: center;
}

.interactive-models-section h2 {
  font-size: 2.0rem; /* Increased font size */
  color: #388087;
  margin-bottom: 1rem;
}

.line-below-heading {
  width: 100px;
  height: 3px;
  background-color: #388087;
  margin: 0 auto 2rem;
}

.line-below-subheading {
  width: 70px;
  height: 2px;
  background-color: #388087;
  margin-bottom: 1rem;
}

/* Interaction Models */
.interaction-model {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  background-color: #f5f6f7;
  padding: 2rem;
  margin-bottom: 2rem;
  border-radius: 10px;
  align-items: center;
}

.interaction-content {
  text-align: left;
  font-size: 1.1rem; /* Increased font size */
}

.interaction-content h3 {
  font-size: 1.5rem;
  color: #388087;
  margin-bottom: 1rem;
}

.interaction-content ul {
  list-style: none;
  padding: 0;
  margin: 0 0 1rem;
}

.interaction-content ul li {
  margin-bottom: 1.5rem;
  font-size: 1.3rem;
  color: #374957;
  position: relative;
  padding-left: 2.4rem;
}

.interaction-content ul li:before {
  content: '\2713'; /* Checkmark */
  color: #388087;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 1.2rem;
}

/* Icons */
.interaction-icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.interaction-icon {
  background-color: #ffffff; /* Circle background */
  border-radius: 50%; /* Circular shape */
  width: 100px; /* Circle size */
  height: 100px; /* Circle size */
  display: flex;
  justify-content: center; /* Center icon horizontally */
  align-items: center; /* Center icon vertically */
  box-shadow: 0 4px 6px rgba(33, 58, 128, 0.278);
  margin-bottom: 1rem; /* Space below the circle */
}

.icon {
  font-size: 2.5rem; /* Adjust icon size as needed */
}

.icon.fa-heartbeat {
  color: #ff5c5c;
}

.icon.fa-hospital {
  color: #388087;
}

/* Disable scroll on body */
.no-scroll {
  overflow: hidden;
}

/* Overlay stays the same */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Dark transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal box with better scroll support */
.modal-content {
  background-color: #ebe8e8;
  color: #388087;
  padding: 0; /* No padding on the content box, handled by body */
  max-width: 700px;
  width: 90%;
  max-height: 90vh;
  overflow-y: hidden;
  border: 5px solid #388087;
  border-radius: 3px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  position: relative;
  font-size: 1rem;
}
/* Header section, fixed to the top of the modal */
.modal-header {
  background-color: #388087;
  color: #F6F6F2;
  padding: 25px;
  font-size: 1.5rem;
  position: sticky; /* Sticky header that stays at the top */
  top: 0;
  z-index: 2; /* Ensures it stays on top of the content */
}

/* Close button */
.close-btn {
  position: absolute; /* Keep it inside the modal, relative to .modal-content */
  top: 10px; /* Position it aligned with the header */
  right: 15px; /* Align it to the right of the modal */
  background-color: transparent;
  cursor: pointer;
  color: white;
  padding-top: 2px; /* To center the icon */
}

.close-btn:hover {
  color: #d28181;
}

/* Modal body with padding */
.modal-body {
  padding: 1.5rem;
}

/* Ensure the content inside is properly spaced */
.modal-content p {
  line-height: 1.6;
  margin-bottom: 1rem;
  color: #388087; /* Light color for the text */
}

.modal-content h3, .modal-content h4 {
  color: #388087;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-size: 1.8rem;
}

.modal-content h5{
  background-color: #388087; /* Dark Teal for button */
color: #F6F6F2; /* Off-White for button text */
padding: 15px;
width: 40%;
  box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.2); /* Inner shadow */
  margin-bottom: 1.1rem;
margin-top: 1.4rem;
}

.scrollable-content {
  padding: 1rem;
  overflow-y: auto;
  max-height: calc(90vh - 70px);
}

/* scrollable-content scrollbar styling */
.scrollable-content::-webkit-scrollbar {
  width: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background-color: #d4d5d5; /* Teal scrollbar */
  border-radius: 1px;
}

.scrollable-content::-webkit-scrollbar-track {
  background: #f3f2f2; /* Scrollbar track matches modal background */
}

/* For the interaction part in the pop-up */
.interaction-list {
  text-align: left;
  margin-left: 25px;
}

.interaction-paragraph {
  text-align: left;
  margin-top: 8px;
}

.interaction-list li {
  margin-bottom: 15px;
}
