/* General Section Styling */
.why-cs-stands-out {
    padding: 2rem 1rem;
    background-color: #ffffff;
  }
  
  .why-cs-title {
    font-size: 1.9em;
    font-weight: bold;
    color: #03045e; /* Deep navy blue */
    text-align: left;
    margin-left: 130px;
    position: relative;
  }
  
  .why-cs-title::after {
    content: "";
    display: block;
    width: 60px;
    height: 3px;
    background-color: #ab9ef5; /* Light purple underline */
    margin-top: 5px;
    margin-left: 10px;
  }
  
  /* Points Container */
  .why-cs-points {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 2.5rem;
    margin-left: 90px;
    align-items: flex-start;
    padding: 0 130px; /* Aligns with title */
    max-width: 1200px;
  }
  
  /* Individual Point */
  .why-cs-point {
    display: flex;
    align-items: flex-start;
  }
  
  /* Icon Styling */
  .why-cs-icon {
    width: 50px;
    margin-right: 30px;
    height: 50px;
    flex-shrink: 0;
  }
  
  /* Content Styling */
  .why-cs-content {
    flex: 1;
  }
  
  .why-cs-heading {
    font-family: "Poppins", sans-serif;
    font-size: 25px;
    font-weight: 600; /* Semi-bold */
    color: #222121; /* Dark gray */
    line-height: 60px;
    margin-bottom: 0.2rem;
    text-align: left;
  }
  
  .why-cs-description {
    font-family: "Poppins", sans-serif;
    font-size: 23px;
    margin-left: 80px;
    font-weight: 400; /* Regular */
    color: #6A6A6A; /* Neutral gray for descriptions */
    line-height: 60px;
    text-align: left; /* Left alignment */
  }
  @media (max-width: 768px) {
    .why-cs-stands-out {
        padding: 1.5rem 1rem; /* Adjust padding for smaller screens */
    }

    .why-cs-title {
        font-size: 1.5em; /* Reduce font size for better readability */
        text-align: center; /* Center-align the title */
        margin-left: 0; /* Remove left margin */
    }

    .why-cs-title::after {
        margin-left: auto; /* Center the underline */
        margin-right: auto;
    }

    .why-cs-points {
        margin-left: 0; /* Reset margin */
        padding: 0 1rem; /* Add some padding for spacing */
        align-items: center; /* Center-align points */
    }

    .why-cs-point {
        flex-direction: column; /* Stack icon and content vertically */
        align-items: center; /* Center-align icon and text */
        gap: 1rem; /* Add spacing between icon and content */
        text-align: center; /* Center-align text */
    }

    .why-cs-icon {
        width: 40px; /* Adjust icon size */
        height: 40px;
        margin: 0 auto; /* Center icon */
    }

    .why-cs-content {
        text-align: center; /* Center-align content */
    }

    .why-cs-heading {
        font-size: 1.2rem; /* Reduce heading font size */
        line-height: 1.5rem; /* Adjust line height */
        margin-bottom: 0.5rem;
    }

    .why-cs-description {
        font-size: 1rem; /* Reduce description font size */
        line-height: 1.4rem; /* Adjust line height */
        margin-left: 0; /* Remove left margin */
    }
}
