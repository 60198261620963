/* Base styles for the popup overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  /* Transition for opening */
}

/* Show the popup */
.popup-overlay.active {
  opacity: 1;
  pointer-events: auto;
}

/* Popup box styles */
.popup-box {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  width: 400px;
  max-width: 100%;
  position: relative;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  /* Transition for opening */
  transform: scale(0.9);
  /* Initial scale for transition effect */
}

.popup-overlay.active .popup-box {
  transform: scale(1);
  /* Scale to normal size when active */
}

/* Title styling */
.popup-box h2 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #333;
  text-align: center;
}

/* Form group styling */
.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-size: 1rem;
  margin-bottom: 5px;
  color: #333;
  text-align: left;
  /* Align labels to the left */
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 1rem;
  box-sizing: border-box;
  /* Ensure padding does not affect width */
}

.form-group textarea {
  resize: vertical;
  /* Allow vertical resizing only */
  min-height: 100px;
  /* Minimum height for textarea */
}

/* Spinner size adjustment */
.spinner {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

/* Action buttons styling */
.popup-actions {
  display: flex;
  justify-content: flex-end;
  /* Align buttons to the right */
}

.submit-btn,
.cancel-btn {
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  margin-left: 10px;
}

.submit-btn {
  background-color: #388087;
  /* Dark Teal */
  color: #fff;
}

.cancel-btn {
  background-color: #131b1b;
  /* Red */
  color: #fff;
}

.submit-btn:hover,
.cancel-btn:hover {
  opacity: 0.8;
  /* Slightly change opacity on hover for better UX */
}
